import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useReasonSelectionStore = defineStore('reasonSelectionStore', () => {
  const showReasonWithRestriction        = ref(false);
  const hasSeenReasonWithRestriction     = ref(false);
  const hasSelectedReasonWithRestriction = ref(false);
  const isRestrictionLoading             = ref(false);

  const resetReasonsSelectionStore = () => {
    showReasonWithRestriction.value    = false;
    hasSeenReasonWithRestriction.value = false;
    hasSeenReasonWithRestriction.value = false;
    isRestrictionLoading.value         = false;
  }

  return {
    showReasonWithRestriction,
    hasSeenReasonWithRestriction,
    hasSelectedReasonWithRestriction,
    isRestrictionLoading,
    resetReasonsSelectionStore
  };
});
